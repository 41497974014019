import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Img from "gatsby-image"
// import readingProgress from '../components/readingProgress'
import Sharer from '../components/Sharer'


export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  image,
  photographer,
  helmet,
  url
  // readingProgress,
}) => {
  const PostContent = contentComponent || Content
  return (
    <div>
      <header className="post-header grid grid-two border border-bottom">
        <div className="grid-item">
        <div
                className="full-width-image-container margin-top-0"
                style={{
                  backgroundImage: `url(${
                    !!image.childImageSharp
                      ? image.childImageSharp.fluid.src
                      : image
                  })`,
                }}
              ></div>
          <Img 
            fluid={image.childImageSharp.fluid} 
            alt="test" 
            style={{
              opacity: `0`,
              width: `0`,
              height: `0`
            }} 
          />
        </div>
        <div className="grid-item center border border-left">
          <h1 className="blog-title">
           {title}<br />&mdash;{description}
          </h1>
 
        </div>
      </header>
      
      <br></br>
        <Sharer
          location={url}
          title={title}
          image={image}
        />

      <p className="caption">Photo by {photographer}</p>
      <section className="section post">
        {helmet || ''}
        <div className="container content"> 
              <PostContent content={content} />
              {/* {tags && tags.length ? (
                <div style={{ marginTop: `4rem` }}>
                  <h4>Tags</h4>
                  <ul className="taglist">
                    {tags.map(tag => (
                      <li key={tag + `tag`}>
                        <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null} */}
        </div>
      </section>
      {/* {readingProgress} */}
    </div>
  )
}

BlogPostTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  number: PropTypes.string,
  helmet: PropTypes.object,
  photographer: PropTypes.string,
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data
  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        image={post.frontmatter.image}
        photographer={post.frontmatter.photographer}
        url={`https://thegeniuslist.com${post.fields.slug}`}

        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={post.frontmatter.description}
            />
            <meta property="og:title" content={post.frontmatter.title} />
            <meta
              property="og:description" 
              content={post.frontmatter.description}
              /*content={`${post.frontmatter.title}, ${post.frontmatter.description}`}*/
            />
            <meta property="og:url" content={`https://thegeniuslist.com${post.fields.slug}`} />
            <meta property="og:image" content={`https://www.thegeniuslist.com${post.frontmatter.image.childImageSharp.fluid.src}`} />
            <meta property="og:type" content="website" />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        number={post.frontmatter.number}
      />

    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        photographer
        image {
          childImageSharp {
              fluid(maxWidth: 2048) {
                src
                srcSet
                aspectRatio
                sizes
              }
          }
        }
        number
        tags
      }
    }
  }
`
