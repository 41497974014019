import React from 'react'
import {
    FacebookShareButton,
    TwitterShareButton,
/*    LinkedinShareButton,
 *    To share rich media on linked in you have to become linked in business partner.*/
    FacebookIcon,
    TwitterIcon,
/*    LinkedinIcon,*/
    EmailShareButton,
    EmailIcon,
} from 'react-share'
import styles from '../styles/styles.css'

const Sharer = ({location, title, image}) => {

/*  const shareUrl = `https://thegeniuslist.com/${location.pathname}`*/
  const shareUrl = location
  const shareTitle = title 
  const shareImage = image
  return (
    <div className="genius-sharer-container">
      <div className="share-content">
          <span className="share-head">
              {/*<h3><em>Share!</em></h3>*/}
          </span>
          <span className="genius-sharer-facebook" >
              <FacebookShareButton
                  url={shareUrl}
                  quote={shareTitle}
                  className="genius-sharer-facebook__sharebutton">
                  {/*<FacebookIcon size={32} round />*/}
              </FacebookShareButton>
          </span>
          <span className="genius-sharer-twitter">
              <TwitterShareButton
                  url={shareUrl}
                  title={shareTitle}
                  className="genius-sharer-twitter__sharebutton">
                  {/*<TwitterIcon size={32} round />*/}
              </TwitterShareButton>
          </span>
          <span className="genius-sharer-email">
              <EmailShareButton
                  url={shareUrl}
                  subject={shareTitle}
                  body={'Hey check out this article I found on the Genius List'}
                  className="genius-sharer-email__sharebutton">
                  {/*<EmailIcon size={32} round />*/}
              </EmailShareButton>
          </span>
 
       </div>
    </div>
  )
}

export default Sharer
